import Banner from './components/banner/Banner';
import Contact from './components/contact/Contact';

import Navbar from './components/navbar/Navbar';
import Projects from './components/projects/Projects';
import Resume from './components/resume/Resume';

function App() {
  return (
    <div className="w-full h-auto bg-bodyColor text-lightText px-4">
      <Navbar />
      <Banner />

      <Projects />
      <Resume />
      <Contact />

      <div className="max-w-screen-xl mx-auto"></div>
    </div>
  );
}

export default App;
